import "./admin.css";

import { useEffect, useState } from "react";
import { getPhysicianManagementData } from "./components/adminCard/lib/adminCardLib_PhysicianManagement";
import { getAssessmentTypeManagementData } from "./components/adminCard/lib/adminCardLib_AssessmentTypeManagement";
import { getCard03Data } from "./components/adminCard/lib/adminCardLib_Card03";
import { getCard04Data } from "./components/adminCard/lib/adminCardLib_Card04";
import { getCard05Data } from "./components/adminCard/lib/adminCardLib_Card05";
import { getCard06Data } from "./components/adminCard/lib/adminCardLib_Card06";
import { getCard07Data } from "./components/adminCard/lib/adminCardLib_Card07";
import { getCard08Data } from "./components/adminCard/lib/adminCardLib_Card08";
import { getCard09Data } from "./components/adminCard/lib/adminCardLib_Card09";
import { getCard10Data } from "./components/adminCard/lib/adminCardLib_Card10";
import { getCard11Data } from "./components/adminCard/lib/adminCardLib_Card11";
import { getCard12Data } from "./components/adminCard/lib/adminCardLib_Card12";
import { getBusLogData } from "./components/adminCard/lib/adminCardLib_BusLog";
import { AdminCardSection } from "./components/adminCardSection/adminCardSection";
import { getFDARouteManagementData } from "./components/adminCard/lib/adminCardLib_FDARouteManagement";
import { getFDADosageTypeManagementData } from "./components/adminCard/lib/adminCardLib_FDADosageTypeManagement";
import { getFDAMedicationManagementData } from "./components/adminCard/lib/adminCardLib_FDAMedicationManagement";

export const Admin = () => {
    //=== Use States ==========================================================

    //Admin cards collection
    const [adminFeatureCards, setAdminFeatureCards] = useState([]);

    //filter value state
    const [filter, setFilter] = useState(null);

    //=== Use Effects =========================================================
    useEffect(() => {
        //Setting Cards
        const adminCards = [];

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getPhysicianManagementData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getAssessmentTypeManagementData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getBusLogData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getFDAMedicationManagementData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getFDARouteManagementData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getFDADosageTypeManagementData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getCard03Data();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getCard04Data();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getCard05Data();
            adminCards.push(sectionContent);
        }

        //ToDos: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getCard06Data();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getCard07Data();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getCard08Data();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getCard09Data();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getCard10Data();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getCard11Data();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getCard12Data();
            adminCards.push(sectionContent);
        }

        //Setting cards...
        setAdminFeatureCards(adminCards);
    }, []);

    //=== Handlers ============================================================

    //handle search input change
    const handleSearchInput = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);
    };

    //=== Render ==============================================================
    return (
        <>
            <section id="admin">
                <div className="container container-wide mt-5 pt-4">
                    <div className="row">
                        <div className="col-sm-7 col-md-9 col-lg-9">
                            <h4 className="pt-4 pb-0 mb-0 ps-3 float-start text-primary">Features</h4>
                        </div>
                        <div className="col-sm-5 col-md-3 col-lg-3 align-end">
                            <input type="text" className="mt-3 form-control form-control" placeholder="Search...." onChange={handleSearchInput} />
                        </div>
                    </div>

                    <hr />

                    {adminFeatureCards && <AdminCardSection title="ATK" adminCards={[...adminFeatureCards.filter((i) => i?.section === "ATK")]} filter={filter} />}
                    {adminFeatureCards && <AdminCardSection title="Jobs" adminCards={[...adminFeatureCards.filter((i) => i?.section === "Jobs")]} filter={filter} />}
                </div>
            </section>
        </>
    );
};
