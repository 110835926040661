export const getFDARouteManagementData = () => {
    return {
        title: "FDA Routes",
        description: "Manage FDA Route Types without pain.",
        icon: "CardIcon_fdaroute.svg",
        iconHover: "CardIcon_fdaroute_blue.svg",
        cardLink: "/fdaRouteManagement",
        section: "ATK",
    };
};
