export const getCard04Data = () => {
    return {
        title: "Admin Feature 04",
        description: "Personalize branch configurations with ease.",
        icon: "CardIcon_Card04.svg",
        iconHover: "CardIcon_Card04_blue.svg",
        cardLink: "/",
        section: "Jobs",
    };
};
