export const getCard12Data = () => {
    return {
        title: "Receiving Config",
        description: "Harness the ability to control every setting.",
        icon: "CardIcon_Card12.svg",
        iconHover: "CardIcon_Card12_blue.svg",
        cardLink: "/",
        section: "Jobs",
    };
};
