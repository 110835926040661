export const getFDADosageTypeManagementData = () => {
    return {
        title: "FDA Dosage Types",
        description: "Manage FDA Dosage Types easily.",
        icon: "CardIcon_fdadosagetype.svg",
        iconHover: "CardIcon_fdadosagetype_blue.svg",
        cardLink: "/fdaDosageTypeManagement",
        section: "ATK",
    };
};
