export const getFDAMedicationManagementData = () => {
    return {
        title: "FDA Medications",
        description: "Manage FDA Medications.",
        icon: "CardIcon_fdamedication.svg",
        iconHover: "CardIcon_fdamedication_blue.svg",
        cardLink: "/fdaMedicationManagement",
        section: "ATK",
    };
};
