import "./PageLayout.css";
import logo from "./cplogo.png";

import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { SignOutButton } from "../SignOutButton";
import { Link } from "react-router-dom";
import { ProfilePhoto } from "../profilePhoto/ProfilePhoto";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <>
            <Navbar expand="lg" fixed="top" className="nav-gradient ms-0 ps-0 mb-0 pb-0">
                <Container fluid>
                    <Navbar.Brand as={Link} to="/" className="ps-3">
                        <div style={{ display: "inline-flex", alignItems: "center" }}>
                            <img src={logo} alt="Caring People" height="40px" width="52px" />
                            <span className="text-muted h4 ps-2 pt-2">
                                {process.env.REACT_APP_NAME} <span className="versionNumber">v{process.env.REACT_APP_VERSION}</span>
                            </span>
                        </div>
                    </Navbar.Brand>
                    <AuthenticatedTemplate>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto ps-4">
                                {/*
                                <Nav.Link as={Link} to="/clientList">
                                    <span className="myNavLink">Clients</span>
                                </Nav.Link>
                                <Nav.Link as={Link} to="/">
                                    <span className="myNavLink">Visits</span>
                                </Nav.Link>
                                */}
                            </Nav>
                            <Nav>
                                <ProfilePhoto />
                                &nbsp;&nbsp;
                                <SignOutButton />
                            </Nav>
                        </Navbar.Collapse>
                    </AuthenticatedTemplate>
                </Container>
            </Navbar>
            {props.children}
        </>
    );
};
