import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
//import { ProfileContent } from "../../components/ProfileContent";
import "./home.css";
import { WelcomePanel } from "./components/welcomePanel/welcomePanel";
import { PhysicianManagement } from "../physicianManagement/physicianManagement";
import { Admin } from "./components/admin/admin";

export const Home = () => {
    return (
        <section>
            <UnauthenticatedTemplate>
                <div className="bgimg-home" />
                <center>
                    <WelcomePanel />
                </center>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Admin />
                {/*
                <PhysicianManagement />
                */}
            </AuthenticatedTemplate>
        </section>
    );
};
