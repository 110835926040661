import "./adminCardSection.css";

import { AdminCard } from "../adminCard/adminCard";
import { useEffect, useState } from "react";

export const AdminCardSection = ({ adminCards, filter, title }) => {
    //=== Use States ==========================================================

    //Visible cards collection
    const [visibleCards, setVisibleCards] = useState(adminCards);

    //=== Use Effects =========================================================
    useEffect(() => {}, []);

    //update display data when data || filter changes
    useEffect(() => {
        setVisibleCards(
            adminCards.filter(({ title, description }) => {
                //filter text
                let matchText = true;

                if (filter) matchText = title?.toLowerCase().includes(filter) || description?.toLowerCase().includes(filter);

                return matchText;
            })
        );
    }, [filter, adminCards]); // eslint-disable-line

    //=== Render ==============================================================
    return (
        <>
            <section id="admin">
                <div className="container container-wide">
                    {title && (
                        <>
                            <div className="row">
                                <div className="col-sm-7 col-md-9 col-lg-9">
                                    <h5 className="pt-4 pb-0 mb-0 ps-0 float-start text-primary">{title}</h5>
                                </div>
                                <div className="col-sm-5 col-md-3 col-lg-3 align-end">{/*<input type="text" className="mt-3 form-control form-control" placeholder="Search...." onChange={handleSearchInput} />*/}</div>
                            </div>

                            <hr />
                        </>
                    )}

                    <section id="AdminFeaturesCards">
                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 ms-0 ps-0 mb-4">
                            {visibleCards.map((item, index) => {
                                return (
                                    <div className="col mt-3 mx-0" key={index}>
                                        <AdminCard index={index} iconClass={item?.iconClass} icon={item?.icon} iconHover={item?.iconHover} title={item?.title} description={item?.description} cardLink={item?.cardLink} />
                                    </div>
                                );
                            })}

                            {visibleCards.length === 0 && <div className="col-12 mt-4 ms-4">Oops! It seems that there are no cards available with these search criteria.</div>}
                        </div>
                    </section>
                </div>
            </section>
        </>
    );
};
