import React from "react";
import { PageLayout } from "./components/pageLayout/PageLayout.jsx";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home/home";
import { AuthenticatedTemplate, MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { PhysicianManagement } from "./pages/physicianManagement/physicianManagement.jsx";
import { AssessmentCards } from "./pages/assessmentCards/assessmentCards.jsx";
import { BusLog } from "./pages/busLog/busLog.jsx";
import { FDARouteManagement } from "./pages/fdaRouteManagement/fdaRouteManagement.jsx";
import { FDADosageTypeManagement } from "./pages/fdaDosageTypeManagement/fdaDosageTypeManagement.jsx";
import { FDAMedicationManagement } from "./pages/fdaMedicationManagement/fdaMedicationManagement.jsx";

function App() {
    return (
        <>
            <BrowserRouter>
                <PageLayout />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/busLog" element={<BusLog />} />
                    <Route path="/physicianManagement" element={<PhysicianManagement />} />
                    <Route path="/fdaDosageTypeManagement" element={<FDADosageTypeManagement />} />
                    <Route path="/fdaMedicationManagement" element={<FDAMedicationManagement />} />
                    <Route path="/fdaRouteManagement" element={<FDARouteManagement />} />
                    <Route path="/assessmentCards" element={<AssessmentCards />} />
                </Routes>
            </BrowserRouter>
            {/* 
            <AuthenticatedTemplate>
                <p>This will only render if a user is signed-in.</p>
                <WelcomeUser />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>This will only render if a user is not signed-in.</p>
            </UnauthenticatedTemplate>
            */}
        </>
    );
}

export default App;
