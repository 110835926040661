export const getCard07Data = () => {
    return {
        title: "Admin Feature 07",
        description: "Toggle branch settings with a simple switch.",
        icon: "CardIcon_Card07.svg",
        iconHover: "CardIcon_Card07_blue.svg",
        cardLink: "/",
        section: "Jobs",
    };
};
