export const getCard10Data = () => {
    return {
        title: "Truck Management",
        description: "Hit the road with confidence: Supercharge your truck management.",
        icon: "CardIcon_Card10.svg",
        iconHover: "CardIcon_Card10_blue.svg",
        cardLink: "/",
        section: "Jobs",
    };
};
