//css
import "./fdaRouteModal.css";

//Icons
//import icon from "../../../../assets/wheelchair-solid-green.svg";

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
//import Select from "react-select";
//import { updateAssistiveDevicesSection } from "../../../../api/nurseAssessment/assistiveDevicesAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { saveFDARoute } from "../../../../api/fdaRouteAPI";

export const FDARouteModal = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //FDARoute data state
    const [fdaRouteData, setFDARouteData] = useState(null);

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        const newData = {
            ...data,
        };
        setFDARouteData(newData);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: fdaRouteData?.id };
        setFDARouteData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = {
            ...fdaRouteData,
        };
        
        //===========================================================
        //Saving FDARoute Data

        saveFDARoute(saveObj)
            .then((ret) => {
                setFDARouteData(ret);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...fdaRouteData, [name]: value };
        setFDARouteData(newData);
    };

    return (
        <Modal dialogClassName="fdaRouteModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            {/*
                            <img src={icon} width="24px" height="24px" alt="" className="icon mt-0 pt-0"></img>
                            */}
                        </span>
                        <span className="ps-2">Route</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Name</span>
                            <input name="name" type="text" className="form-control" placeholder={`Route Name`} value={fdaRouteData?.name ?? ""} onChange={handleFieldChange} maxLength="30" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={false} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
