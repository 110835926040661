import "./fdaDosageTypeListTableFooter.css";
import { PageNumberOptions } from "../../../../components/pageNumberOptions/pageNumberOptions";

export const FDADosageTypeListTableFooter = ({ handlePageNumberChange, handlePageSizeChange, numPages, numRecords, pageSize }) => {
    return (
        <tr>
            <td colSpan={"7"}>
                <div className="row">
                    <div className="col-lg-4 col-md-5 col-sm-6 col-xs-7">
                        <div className="row">
                            <div className="col-xs-1 col-sm-1 col-md-2 col-lg-1">
                                <label htmlFor="PageNumber" className="small pageLabel">
                                    Page
                                </label>
                                <label htmlFor="PageNumber" className="small pageLabelAlternative">
                                    Pg.
                                </label>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-3">
                                <select className="form-select form-select-sm" id="PageNumber" name="PageNumber" onChange={handlePageNumberChange}>
                                    <PageNumberOptions numItems={numPages} />
                                </select>
                            </div>
                            <div className="col-xs-1 col-sm-1 col-md-2 col-lg-1">
                                <label htmlFor="PageSize" className="small">
                                    Size
                                </label>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-3">
                                <select className="form-select form-select-sm" id="PageSize" name="PageSize" onChange={handlePageSizeChange} value={pageSize}>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="All">All</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-sm-6 col-xs-5 text-end">
                        # Records: <b>{numRecords}</b>
                    </div>
                </div>
            </td>
        </tr>
    );
};
