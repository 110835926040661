import { useState } from "react";
import "./assessmentCard.css";
import { useEffect } from "react";

export const AssessmentCard = ({ index, item, cardClick }) => {
    const [cardApplicable, setCardApplicable] = useState(item?.applicable);

    const cardClickHandler = () => setCardApplicable(!cardApplicable);

    useEffect(() => {
        setCardApplicable(item?.applicable);
    }, [item?.applicable]);

    return (
        <div className={cardApplicable ? "card assessmentCard assessmentCardApplicable" : "card assessmentCard assessmentCardNotApplicable"} onClick={cardClick}>
            <div className="card-body">
                <h5 className={cardApplicable ? "card-title assessmentCardTitle text-primary" : "card-title assessmentCardTitle text-danger"}>
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                        <span>
                            {!cardApplicable && <img className="cardDefaultIcon" src={`${process.env.PUBLIC_URL}/assets/images/${item?.icon}`} width="36px" height="36px" alt=""></img>}
                            {cardApplicable && <img className="cardHoverIcon" src={`${process.env.PUBLIC_URL}/assets/images/${item?.iconHover}`} width="40px" height="40px" alt=""></img>}
                        </span>
                        <span className="ps-2 h5">{item?.title}</span>
                    </div>
                </h5>
                <p className="card-text small pt-2">{cardApplicable ? "Applicable" : "Not Applicable"}</p>
            </div>
        </div>
    );
};
