import { formatPhone } from "../../../../lib/formatPhone";

export const FDADosageTypeListTableBody = ({ currentPage, numberPerPage, items, loadingError, loading, handleItemClick }) => {
    const begin = (Number(currentPage) - 1) * Number(numberPerPage);
    const end = begin + Number(numberPerPage);
    const data = items?.slice(begin, end) ?? [];

    if (loadingError)
        return (
            <tr>
                <td colSpan="7" className="table-danger text-center">
                    {loadingError}
                </td>
            </tr>
        );

    return data.length === 0
        ? !loading && (
              <tr>
                  <td colSpan={"4"} className="table-danger text-center">
                      No Routes to display
                  </td>
              </tr>
          )
        : data.map(({ name, id }) => {
              return (
                  <tr key={id} onClick={() => handleItemClick(id)} style={{ cursor: "pointer" }}>
                      <td className="small" valign="middle">
                          {name ?? "-"}
                      </td>
                  </tr>
              );
          });
};
