export const formatPhone = (num) => {
    try {
        // Remove nn digit characters
        const justNumbers = num?.toString().replace(/\D/g, "");

        // format
        var formattedPhone = justNumbers.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

        return formattedPhone;
    } catch (error) {}

    return num;
};
