export const getCard05Data = () => {
    return {
        title: "User Management",
        description: "Efficient user management made simple: Your control center awaits.",
        icon: "CardIcon_Card05.svg",
        iconHover: "CardIcon_Card05_blue.svg",
        cardLink: "/",
        section: "Jobs",
    };
};
