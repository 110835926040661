import "./welcomePanel.css";
import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { SignInButton } from "../../../../components/SignInButton";
import icon from "../../../../assets/6533310.jpg";

export const WelcomePanel = () => {
    const [showPanel, setShowPanel] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowPanel(true);
        }, 300);
    }, []);

    return (
        <div className={`welcomePanel-card-container ${showPanel ? "show" : ""}`}>
            <Container>
                <Card className="welcomePanel-rounded-corner col-md-10">
                    <Card.Body>
                        <Card.Text>
                            <div className="row text-start">
                                <div className="col-md-7 pt-2 mt-4 ps-4">
                                    <h3 className="text-success">Welcome to Administration Center</h3>
                                    <br />
                                    <p className="text-muted h5 mb-2">Efficiently manage Caring People's applications.</p>
                                    <p className="text-muted h5 mb-4">To get started, click the sign in button.</p>
                                    <SignInButton />
                                </div>
                                <div className="col-md-5 text-center">
                                    <div className="image-container d-none d-md-block">
                                        <img src={icon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};
