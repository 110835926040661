//css
import "./physicianModal.css";

//Icons
//import icon from "../../../../assets/wheelchair-solid-green.svg";

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
//import Select from "react-select";
//import { updateAssistiveDevicesSection } from "../../../../api/nurseAssessment/assistiveDevicesAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { savePhysician } from "../../../../api/physicianAPI";

export const PhysicianModal = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Physician data state
    const [physicianData, setPhysicianData] = useState(null);

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        const newData = {
            ...data,
            mailing_Address: data?.mailingAddress?.address,
            mailing_Address2: data?.mailingAddress?.address2,
            mailing_City: data?.mailingAddress?.city,
            mailing_State: data?.mailingAddress?.state,
            mailing_Country: data?.mailingAddress?.country,
            mailing_ZipCode: data?.mailingAddress?.zipCode,
            business_Address: data?.businessAddress?.address,
            business_Address2: data?.businessAddress?.address2,
            business_City: data?.businessAddress?.city,
            business_State: data?.businessAddress?.state,
            business_Country: data?.businessAddress?.country,
            business_ZipCode: data?.businessAddress?.zipCode,
        };
        setPhysicianData(newData);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: physicianData?.id };
        setPhysicianData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = {
            ...physicianData,
            mailingAddress: { Address: physicianData?.mailing_Address, Address2: physicianData?.mailing_Address2, City: physicianData?.mailing_City, State: physicianData?.mailing_State, Country: physicianData?.mailing_Country, ZipCode: physicianData?.mailing_ZipCode },
            businessAddress: { Address: physicianData?.business_Address, Address2: physicianData?.business_Address2, City: physicianData?.business_City, State: physicianData?.business_State, Country: physicianData?.business_Country, ZipCode: physicianData?.business_ZipCode },
        };

        //===========================================================
        //Saving Physician Data

        savePhysician(saveObj)
            .then((ret) => {
                setPhysicianData(ret);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...physicianData, [name]: value };
        setPhysicianData(newData);
    };

    return (
        <Modal dialogClassName="physicianModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            {/*
                            <img src={icon} width="24px" height="24px" alt="" className="icon mt-0 pt-0"></img>
                            */}
                        </span>
                        <span className="ps-2">Physician</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    <div className="row">
                        <div className="col-2">
                            <span className="my-1 d-block">First Name</span>
                            <input name="firstName" type="text" className="form-control" placeholder={`First Name`} value={physicianData?.firstName ?? ""} onChange={handleFieldChange} maxLength="30" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Last Name</span>
                            <input name="lastName" type="text" className="form-control" placeholder={`Last Name`} value={physicianData?.lastName ?? ""} onChange={handleFieldChange} maxLength="30" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Prefix</span>
                            <input name="namePrefix" type="text" className="form-control" placeholder={`Name Prefix`} value={physicianData?.namePrefix ?? ""} onChange={handleFieldChange} maxLength="10" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Suffix</span>
                            <input name="nameSufix" type="text" className="form-control" placeholder={`Name Suffix`} value={physicianData?.nameSufix ?? ""} onChange={handleFieldChange} maxLength="10" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">NPI</span>
                            <input name="npi" type="number" className="form-control" placeholder={`NPI`} value={physicianData?.npi ?? ""} onChange={handleFieldChange} maxLength="10" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">UPIN</span>
                            <input name="upin" type="number" className="form-control" placeholder={`UPIN`} value={physicianData?.upin ?? ""} onChange={handleFieldChange} maxLength="10" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <span className="my-1 d-block">Credential</span>
                            <input name="credential" type="text" className="form-control" placeholder={`Credential`} value={physicianData?.credential ?? ""} onChange={handleFieldChange} maxLength="20" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Gender</span>
                            <input name="gender" type="text" className="form-control" placeholder={`Gender`} value={physicianData?.gender ?? ""} onChange={handleFieldChange} maxLength="10" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Phone</span>
                            <input name="telephone" type="number" className="form-control" placeholder={`Phone`} value={physicianData?.telephone ?? ""} onChange={handleFieldChange} maxLength="12" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Fax</span>
                            <input name="fax" type="number" className="form-control" placeholder={`Fax`} value={physicianData?.fax ?? ""} onChange={handleFieldChange} maxLength="12" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">Type of Provider</span>
                            <input name="typeOfProvider" type="text" className="form-control" placeholder={`Type of Provider`} value={physicianData?.typeOfProvider ?? ""} onChange={handleFieldChange} maxLength="30" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Classification</span>
                            <input name="classification" type="text" className="form-control" placeholder={`Classification`} value={physicianData?.classification ?? ""} onChange={handleFieldChange} maxLength="30" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Specialization</span>
                            <input name="specialization" type="text" className="form-control" placeholder={`Specialization`} value={physicianData?.specialization ?? ""} onChange={handleFieldChange} maxLength="50" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">License Number</span>
                            <input name="licenseNumber" type="text" className="form-control" placeholder={`License Number`} value={physicianData?.licenseNumber ?? ""} onChange={handleFieldChange} maxLength="30" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">License State</span>
                            <input name="licenseState" type="text" className="form-control" placeholder={`License State`} value={physicianData?.licenseState ?? ""} onChange={handleFieldChange} maxLength="20" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Email</span>
                            <input name="email" type="text" className="form-control" placeholder={`Email`} value={physicianData?.email ?? ""} onChange={handleFieldChange} maxLength="100" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                Mailing Address <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <span className="my-1 d-block">Address</span>
                            <input name="mailing_Address" type="text" className="form-control" placeholder={`Address`} value={physicianData?.mailing_Address ?? ""} onChange={handleFieldChange} maxLength="100" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-6">
                            <span className="my-1 d-block">Address 2</span>
                            <input name="mailing_Address2" type="text" className="form-control" placeholder={`Address 2`} value={physicianData?.mailing_Address2 ?? ""} onChange={handleFieldChange} maxLength="100" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <span className="my-1 d-block">City</span>
                            <input name="mailing_City" type="text" className="form-control" placeholder={`City`} value={physicianData?.mailing_City ?? ""} onChange={handleFieldChange} maxLength="100" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">State</span>
                            <input name="mailing_State" type="text" className="form-control" placeholder={`State`} value={physicianData?.mailing_State ?? ""} onChange={handleFieldChange} maxLength="20" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Country</span>
                            <input name="mailing_Country" type="text" className="form-control" placeholder={`Country`} value={physicianData?.mailing_Country ?? ""} onChange={handleFieldChange} maxLength="20" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Zip</span>
                            <input name="mailing_ZipCode" type="text" className="form-control" placeholder={`Zip`} value={physicianData?.mailing_ZipCode ?? ""} onChange={handleFieldChange} maxLength="10" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                Business Address <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <span className="my-1 d-block">Address</span>
                            <input name="business_Address" type="text" className="form-control" placeholder={`Address`} value={physicianData?.business_Address ?? ""} onChange={handleFieldChange} maxLength="100" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-6">
                            <span className="my-1 d-block">Address 2</span>
                            <input name="business_Address2" type="text" className="form-control" placeholder={`Address 2`} value={physicianData?.business_Address2 ?? ""} onChange={handleFieldChange} maxLength="100" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <span className="my-1 d-block">City</span>
                            <input name="business_City" type="text" className="form-control" placeholder={`City`} value={physicianData?.business_City ?? ""} onChange={handleFieldChange} maxLength="100" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">State</span>
                            <input name="business_State" type="text" className="form-control" placeholder={`State`} value={physicianData?.business_State ?? ""} onChange={handleFieldChange} maxLength="20" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Country</span>
                            <input name="business_Country" type="text" className="form-control" placeholder={`Country`} value={physicianData?.business_Country ?? ""} onChange={handleFieldChange} maxLength="20" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Zip</span>
                            <input name="business_ZipCode" type="text" className="form-control" placeholder={`Zip`} value={physicianData?.business_ZipCode ?? ""} onChange={handleFieldChange} maxLength="10" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Notes</span>
                            <textarea name="notes" className="form-control" placeholder={`Notes`} maxLength="1000" onChange={handleFieldChange} value={physicianData?.notes ?? ""} />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={false} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
