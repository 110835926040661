export const getCard09Data = () => {
    return {
        title: "Admin Feature 09",
        description: "Seamlessly manage your configurations at will.",
        icon: "CardIcon_Card09.svg",
        iconHover: "CardIcon_Card09_blue.svg",
        cardLink: "/",
        section: "Jobs",
    };
};
