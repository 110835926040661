export const getBusLogData = () => {
    return {
        title: "ATK Bus Log",
        description: "View ATK bus message log effortlessly.",
        icon: "CardIcon_Log1.svg",
        iconHover: "CardIcon_Log1_blue.svg",
        cardLink: "/busLog",
        section: "ATK",
    };
};
