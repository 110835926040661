export const getCard06Data = () => {
    return {
        title: "Fulfillment Admin",
        description: "Empower your branch's customization journey.",
        icon: "CardIcon_Card06.svg",
        iconHover: "CardIcon_Card06_blue.svg",
        cardLink: "/",
        section: "Jobs",
    };
};
