import "./assessmentCards.css";

import { useEffect, useState } from "react";
import { getCardiopulmonaryData } from "./components/assessmentCard/lib/assessmentCardLib_Cardiopulmonary";
import { getAssistiveDevicesData } from "./components/assessmentCard/lib/assessmentCardLib_AssistiveDevices";
import { getBradenScaleData } from "./components/assessmentCard/lib/assessmentCardLib_BradenScale";
import { getCaregiverClinicalOrientationData } from "./components/assessmentCard/lib/assessmentCardLib_CaregiverClinicalOrientation";
import { getCommentsData } from "./components/assessmentCard/lib/assessmentCardLib_Comments";
import { getDepressionScreenData } from "./components/assessmentCard/lib/assessmentCardLib_DepressionScreen";
import { getDiabeticFocusExamData } from "./components/assessmentCard/lib/assessmentCardLib_DiabeticFocusExam";
import { getFallRiskData } from "./components/assessmentCard/lib/assessmentCardLib_FallRisk";
import { getGastrointestinalData } from "./components/assessmentCard/lib/assessmentCardLib_Gastrointestinal";
import { getGenitourinaryData } from "./components/assessmentCard/lib/assessmentCardLib_Genitourinary";
import { getHomeSafetyData } from "./components/assessmentCard/lib/assessmentCardLib_HomeSafety";
import { getInterventionsData } from "./components/assessmentCard/lib/assessmentCardLib_Interventions";
import { getMedicationsData } from "./components/assessmentCard/lib/assessmentCardLib_Medications";
import { getNeuromuscularData } from "./components/assessmentCard/lib/assessmentCardLib_Neuromuscular";
import { getNurseReviewData } from "./components/assessmentCard/lib/assessmentCardLib_NurseReview";
import { getPhysicalData } from "./components/assessmentCard/lib/assessmentCardLib_Physical";
import { getResponseTeachingData } from "./components/assessmentCard/lib/assessmentCardLib_ResponseTeaching";
import { getWoundCareData } from "./components/assessmentCard/lib/assessmentCardLib_WoundCare";
import { AssessmentCard } from "./components/assessmentCard/assessmentCard";
import { getAllAssessmentConfigs, saveAssessmentConfig } from "../../api/assessmentConfigAPI";
import { isError } from "../../lib/isError";
import { Loading } from "../../components/loading/loading";

export const AssessmentCards = () => {
    //=== Use States ==========================================================
    //const { user } = useAuthContext();

    //cards collection
    const [cards, setCards] = useState([]);

    //Visible cards collection
    const [visibleCards, setVisibleCards] = useState([]);

    //filter value state
    const [filter, setFilter] = useState(null);

    //Admin cards data collection
    const [assessmentCardData, setAssessmentCardData] = useState([]);

    //selected assessment
    const [selectedAssessment, setSelectedAssessment] = useState(null);

    //errors state
    const [loadingError, setLoadingError] = useState(null);
    const [operationError, setOperationError] = useState(null);

    //Loading data flag state
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    //=== Use Effects =========================================================
    useEffect(() => {
        //loading assessment cards data
        loadAllAssessmentCardsData();

        //Setting Cards
        const adminCards = [];

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getAssistiveDevicesData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getBradenScaleData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getCardiopulmonaryData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getCaregiverClinicalOrientationData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getCommentsData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getDepressionScreenData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getDiabeticFocusExamData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getFallRiskData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getGastrointestinalData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getGenitourinaryData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getHomeSafetyData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getInterventionsData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getMedicationsData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getNeuromuscularData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getNurseReviewData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getPhysicalData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getResponseTeachingData();
            adminCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getWoundCareData();
            adminCards.push(sectionContent);
        }

        //Setting cards...
        setCards(adminCards);

        //Setting visible cards...
        setVisibleCards(adminCards);
    }, []);

    //update display data when data || filter changes
    useEffect(() => {
        setVisibleCards(
            cards.filter(({ title, description }) => {
                //filter text
                let matchText = true;

                if (filter) matchText = title?.toLowerCase().includes(filter);

                return matchText;
            })
        );

        //setVisibleCards(cards);
    }, [filter, cards]); // eslint-disable-line

    //=== Methods ============================================================

    const loadAllAssessmentCardsData = () => {
        setLoadingError(null);
        setLoading(true);
        getAllAssessmentConfigs()
            .then((data) => {
                setAssessmentCardData(data);
                setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
                setLoading(false);
            });
    };

    //=== Handlers ============================================================

    //handle search input change
    const handleSearchInput = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);
    };

    const handleAssessmentTypeChange = (e) => {
        const value = e.target.value;
        const newCards = [...cards];
        const selectedData = assessmentCardData.find((i) => i.id === value);

        for (let field in selectedData) {
            if (selectedData.hasOwnProperty(field)) {
                var cd = newCards.find((card) => card.id === field); //.applicable = selectedData[field];
                if (cd) cd.applicable = selectedData[field];
            }
        }
        setCards(newCards);
        setSelectedAssessment(selectedData);
    };

    const handleCardClick = (item) => {
        const newCards = [...cards];
        newCards.find((c) => c.id === item.id).applicable = !item?.applicable;

        setCards(newCards);
    };

    const handleSave = () => {
        setSaving(true);

        const newSelectedAssessment = { ...selectedAssessment };
        cards.forEach((c) => (newSelectedAssessment[c?.id] = c.applicable));

        //===========================================================
        //Saving Assessment Config Data

        saveAssessmentConfig(newSelectedAssessment)
            .then((ret) => {
                //now replacing the saved assessment into AssessmentCardsData
                const newAssessmentData = { ...ret };
                const newAssessmentCardData = assessmentCardData.map((item) => {
                    if (item.id === newAssessmentData.id) return newAssessmentData;
                    else return item;
                });

                setAssessmentCardData(newAssessmentCardData);

                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    //=== Render ==============================================================
    return (
        <>
            <section id="admin">
                <div className="container container-wide mt-5 pt-4">
                    <div className="row">
                        <div className="col-sm-7 col-md-9 col-lg-9">
                            <h4 className="pt-4 pb-0 mb-0 ps-3 float-start text-primary">Assessment Cards Management</h4>
                        </div>
                        <div className="col-sm-5 col-md-3 col-lg-3 align-end">
                            <input type="text" className="mt-3 form-control form-control" placeholder="Search...." onChange={handleSearchInput} />
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <div className="col-sm-5 col-md-6 col-lg-6 ps-4">
                            <select name="assessmentType" className={`form-select`} onChange={handleAssessmentTypeChange} value={null}>
                                {assessmentCardData &&
                                    assessmentCardData.map((item, index) => (
                                        <option key={index} value={item?.id}>
                                            {item?.assessment}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="col-sm-5 col-md-3 col-lg-2">
                            <button type="button" className="btn btn-success" onClick={handleSave}>
                                {!saving && "Save Changes"}
                                {saving && (
                                    <span className="ps-2">
                                        <span className="pe-2">Saving...</span>
                                        <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        <div className="col-sm-2 col-md-3 col-lg-4 pt-2 text-end">{loading && <Loading />}</div>
                    </div>

                    <section id="AdminFeaturesCards">
                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 ms-0 ps-0 mb-4">
                            {visibleCards.map((item, index) => {
                                return (
                                    <div className="col mt-3 mx-0" key={index}>
                                        <AssessmentCard index={index} item={item} cardClick={() => handleCardClick(item)} />
                                    </div>
                                );
                            })}

                            {visibleCards.length === 0 && <div className="col-12 mt-4 ms-4">Oops! It seems that there are no cards available with these search criteria.</div>}
                        </div>
                    </section>
                </div>
            </section>
        </>
    );
};
