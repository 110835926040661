export const getCard08Data = () => {
    return {
        title: "Admin Feature 08",
        description: "Customize your branch: Activate or deactivate settings hassle-free.",
        icon: "CardIcon_Card08.svg",
        iconHover: "CardIcon_Card08_blue.svg",
        cardLink: "/",
        section: "Jobs",
    };
};
