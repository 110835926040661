import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function handleLogout(instance) {
    instance
        .logoutPopup()
        .then(() => {
            alert("deslogou");
        })
        .catch((e) => {
            console.error(e);
        });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();

    const handleLogout2 = (instance) => {
        instance
            .logoutPopup()
            .then(() => {
                navigate("");
            })
            .catch((e) => {
                console.error(e);
            });
    };

    return (
        <Button variant="secondary danger btn-sm" className="ml-auto" onClick={() => handleLogout2(instance)}>
            Sign out
        </Button>
    );
};
