//css
import "./busLogModal.css";

//Icons
//import icon from "../../../../assets/wheelchair-solid-green.svg";

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
//import Select from "react-select";
//import { updateAssistiveDevicesSection } from "../../../../api/nurseAssessment/assistiveDevicesAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { savePhysician } from "../../../../api/physicianAPI";

export const BusLogModal = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Bus Log data state
    const [busLogData, setBusLogData] = useState(null);
    const [busMessage, setBusMessage] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        setBusLogData(data);
        try {
            const obj = JSON.parse(data?.message);
            setBusMessage(obj);
        } catch {
            console.error("error");
        }
    }, [data]);

    //=== Handlers ===========================================================

    return (
        <Modal dialogClassName="physicianModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            {/*
                            <img src={icon} width="24px" height="24px" alt="" className="icon mt-0 pt-0"></img>
                            */}
                        </span>
                        <span className="ps-2">Bus Log Message</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    <div className="row">
                        <div className="col-12">
                            <pre>
                                <code>{JSON.stringify(busMessage, null, 4)}</code>
                            </pre>
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter showPrint={false} handleClose={closeDialog} readOnly={true}>
                    {/*Component to be printed when enable print button is true*/}
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
