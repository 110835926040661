import { formatPhone } from "../../../../lib/formatPhone";

export const PhysicianListTableBody = ({ currentPage, numberPerPage, items, loadingError, loading, handleItemClick }) => {
    const begin = (Number(currentPage) - 1) * Number(numberPerPage);
    const end = begin + Number(numberPerPage);
    const data = items?.slice(begin, end) ?? [];

    if (loadingError)
        return (
            <tr>
                <td colSpan="7" className="table-danger text-center">
                    {loadingError}
                </td>
            </tr>
        );

    return data.length === 0
        ? !loading && (
              <tr>
                  <td colSpan={"4"} className="table-danger text-center">
                      No Physicians to display
                  </td>
              </tr>
          )
        : data.map(({ fullName, email, telephone, specialization, licenseState, id }) => {
              return (
                  <tr key={id} onClick={() => handleItemClick(id)} style={{ cursor: "pointer" }}>
                      <td className="small" valign="middle">
                          {fullName ?? "-"}
                      </td>

                      <td className="small" valign="middle">
                          {formatPhone(telephone) ?? "-"}
                      </td>

                      <td className="small d-none d-md-table-cell" valign="middle">
                          {specialization ?? "-"}
                      </td>

                      <td className="small d-none d-md-table-cell" valign="middle">
                          {licenseState ?? "-"}
                      </td>
                  </tr>
              );
          });
};
