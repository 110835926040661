export const getPhysicianManagementData = () => {
    return {
        title: "Physician Management",
        description: "Take Control of Your Medical Staffing with Ease.",
        icon: "CardIcon_physician.svg",
        iconHover: "CardIcon_physician_blue.svg",
        cardLink: "/physicianManagement",
        section: "ATK",
    };
};
