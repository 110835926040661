import { acquireAccessToken, acquireMSALTokens } from "../lib/acquireAccessToken";
import { Http_BadRequest, Http_OK } from "../lib/httpStatusCodes";
import { sortJson } from "../lib/sortJson";
import { headers } from "./headers";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}Physician`;

//=======================================================================

export const getAllPhysiciansFake = async () => {
    return new Promise((resolve, reject) => {
        resolve([
            {
                fullName: "name 1",
            },
            {
                fullName: "name 2",
            },
        ]);
    });
};

//=======================================================================

export const getAllPhysicians = async () => {
    const tokens = await acquireMSALTokens();
    headers.Authorization = tokens?.accessToken ? `bearer ${tokens.accessToken}` : null;
    headers.AuthorizationIdToken = tokens?.idToken ? `bearer ${tokens.idToken}` : null;
    return new Promise((resolve, reject) => {
        fetch(apiUrl, { method: "GET", headers: headers })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                //sorting data by name
                                let data = sortJson(json.data, "firstName", "string", true);
                                //destructuring address
                                data = data.map((item) => {
                                    return { ...item, fullName: `${item?.lastName}, ${item?.firstName}` };
                                    //return { ...item, address: item?.address?.address, city: item?.address?.city, state: item?.address?.state, zipCode: item?.address?.zipCode };
                                });

                                return res(data);
                            });
                            break;
                        default:
                            rej(`Error loading data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => {
                reject(err);
            });
    });
};

//=======================================================================

export const getPhysicianById = async (id) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/${id}`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                let visitData = [...json?.data?.visits];

                                //converting date
                                visitData = visitData.map((obj) => {
                                    const myLocalDate = new Date(obj?.appointmentDate + "Z").toLocaleString();
                                    return { ...obj, appointmentDate: myLocalDate };
                                });

                                const returnData = { ...json.data, visits: visitData };

                                return res(returnData);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const savePhysician = async (data) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/${data.id ?? "new"}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to create data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================
