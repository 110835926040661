export const getCard03Data = () => {
    return {
        title: "Admin Feature 03",
        description: "Fine-tune settings at your fingertips.",
        icon: "CardIcon_Card03.svg",
        iconHover: "CardIcon_Card03_blue.svg",
        cardLink: "/",
        section: "Jobs",
    };
};
