import { acquireAccessToken, acquireMSALTokens } from "../lib/acquireAccessToken";
import { Http_BadRequest, Http_OK } from "../lib/httpStatusCodes";
import { sortJson } from "../lib/sortJson";
import { headers } from "./headers";

const apiUrl = `${process.env.REACT_APP_ATKAPI_BASE_URL}BusMessageLog`;

//=======================================================================

export const getAllBusLogsFake = async () => {
    return new Promise((resolve, reject) => {
        resolve([
            {
                fullName: "name 1",
            },
            {
                fullName: "name 2",
            },
        ]);
    });
};

//=======================================================================

export const getAllBusLogs = async () => {
    const tokens = await acquireMSALTokens();
    headers.Authorization = tokens?.accessToken ? `bearer ${tokens.accessToken}` : null;
    headers.AuthorizationIdToken = tokens?.idToken ? `bearer ${tokens.idToken}` : null;
    headers["x-functions-key"] = `${process.env.REACT_APP_ATKAPI_BASE_URL_KEY}`;

    return new Promise((resolve, reject) => {
        fetch(apiUrl, { method: "GET", headers: headers })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                //converting date
                                const logData = json.data.map((obj) => {
                                    const myLocalDate = new Date(obj?.createdDate + "Z").toLocaleString();
                                    return { ...obj, createdDate: myLocalDate };
                                });

                                //sorting data by name
                                const data = sortJson(logData, "createdDate", "datetime", true);
                                return res(data);
                            });
                            break;
                        default:
                            rej(`Error loading data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => {
                reject(err);
            });
    });
};

//=======================================================================

export const getBusLogById = async (id) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;
    headers["x-functions-key"] = `${process.env.REACT_APP_ATKAPI_BASE_URL_KEY}`;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/${id}`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                let visitData = [...json?.data?.visits];

                                //converting date
                                visitData = visitData.map((obj) => {
                                    const myLocalDate = new Date(obj?.appointmentDate + "Z").toLocaleString();
                                    return { ...obj, appointmentDate: myLocalDate };
                                });

                                const returnData = { ...json.data, visits: visitData };

                                return res(returnData);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================
