//=== Style =====
import "./fdaRouteManagement.css";

import { useEffect, useState } from "react";
import { ErrorMessage } from "../../components/errorMessage/errorMessage.jsx";
import { Loading } from "../../components/loading/loading";
import useWindowDimensions from "../../lib/hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import { sortJson } from "../../lib/sortJson";
import { JSONToCSVConvertor } from "../../lib/jsonToCSVConvertor";
import { fileTimeStamp } from "../../lib/fileTimeStamp";
import { FDARouteListTableBody } from "./components/fdaRouteListTableBody/fdaRouteListTableBody";
import { FDARouteListTableFooter } from "./components/fdaRouteListTableFooter/fdaRouteListTableFooter";
import { isError } from "../../lib/isError";
import { getAllFDARoutes } from "../../api/fdaRouteAPI";
import { FDARouteModal } from "./components/fdaRouteModal/fdaRouteModal";

export const FDARouteManagement = () => {
    //Using react-router-dom navigate
    let navigate = useNavigate();

    //Loading data flag state
    const [loading, setLoading] = useState(false);

    //errors state
    const [loadingError, setLoadingError] = useState(null);
    const [operationError, setOperationError] = useState(null);

    //FDARoute list data state
    const [data, setData] = useState([]);

    //FDARoute list filtered data state
    const [displayData, setDisplayData] = useState([]);

    //Selected FDARoute
    const [selectedFDARoute, setSelectedFDARoute] = useState(null);

    //Grid records per page state
    const [pageSize, setPageSize] = useState(15);

    //Grid page # state
    const [pageNumber, setPageNumber] = useState(1);

    //Grid number of pages state
    const [numPages, setNumPages] = useState(null);

    //order by direction state
    const [sortAsc, setSortAsc] = useState(true);

    //filter value state
    const [filter, setFilter] = useState(null);

    const { height } = useWindowDimensions();

    //assessments Dialogs
    const [showFDARouteModal, setShowFDARouteModal] = useState(false);

    //Event handlers =====================================================

    //handle download csv
    const handleDownloadClick = () => {
        JSONToCSVConvertor(
            displayData?.map((item) => ({ ...item, status: item?.status ?? "Not Started" })),
            `Clients_${fileTimeStamp()}`,
            true
        );
    };

    //handle page number changes
    const handlePageNumberChange = (e) => {
        setPageNumber(e.target.value);
    };

    //handle page size changes
    const handlePageSizeChange = (e) => {
        e.target.value === "All" ? setPageSize(data.length) : setPageSize(e.target.value);
        //when pagesize changes, reset to page 1
        setPageNumber(1);
    };

    //handle reload button click
    const handleReloadClick = () => {
        loadAllFDARoutes();
    };

    //handle add new click
    const handleAddNew = () => {
        setSelectedFDARoute(null);
        setShowFDARouteModal(true);
    };

    //handle row click
    const handleRowClick = (id) => {
        setSelectedFDARoute(data?.find((i) => i.id === id));
        setShowFDARouteModal(true);
    };

    //handle search input change
    const handleSearchInput = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);
    };

    //handle sort columns
    const handleSortClick = (prop, propType) => {
        setSortAsc((sortAsc) => !sortAsc);
        setDisplayData(sortJson(displayData, prop, propType, sortAsc));
    };

    //=== Methods ============================================================

    const loadAllFDARoutes = () => {
        setLoadingError(null);
        setLoading(true);
        getAllFDARoutes()
            .then((data) => {
                setData(data);
                setDisplayData(data);
                setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
                setLoading(false);
            });
    };

    //Use Effects ===========================================================

    //Loading Clients and defining list page size depending window height...
    useEffect(() => {
        //Defining page size depending window height
        if (height > 600 && height < 900) setPageSize(10);
        else if (height > 900) setPageSize(15);
        else setPageSize(5);

        loadAllFDARoutes();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //update # pages when displayData || pageSize changes
    useEffect(() => {
        pageSize === "All" ? setNumPages(1) : setNumPages(Math.ceil(displayData?.length ?? 0 / pageSize));
    }, [displayData, pageSize]);

    //update display data when data || filter changes
    useEffect(() => {
        setDisplayData(
            !filter
                ? data
                : data?.filter(({ name }) => {
                      //filter text
                      let matchText = true;

                      if (filter) matchText = name?.toLowerCase().includes(filter);

                      return matchText;
                  })
        );

        //when filter, reset to page 1
        setPageNumber(1);
    }, [data, filter]); // eslint-disable-line

    return (
        <div className="container-fluid mt-5 pt-4">
            <div className="row">
                <div className="col-12">
                    <div className="row pb-0 mb-0">
                        <div className="col-4 col-md-3 col-xl-2 pb-0 mb-0">
                            <h4 className="pt-3 text-success ps-3">FDA Routes</h4>
                        </div>
                        {loading && (
                            <div className="col-8 col-md-9- col-xl-10  pt-4 text-end pe-4 pb-0 mb-0">
                                <Loading />
                            </div>
                        )}
                        {operationError && (
                            <div className="col-8 col-md-9 col-xl-10 text-end pe-4 pb-0 mb-0 pt-3">
                                <ErrorMessage msg={operationError} size="small" />
                            </div>
                        )}
                    </div>
                    <hr className="pt-0 mt-0"></hr>
                    <section id="RouteManagementSection">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-sm-7 col-md-9 col-lg-9 text-success">
                                            <button className="btn btn-primary btn-sm" onClick={handleAddNew}>
                                                Add new Route
                                            </button>
                                        </div>
                                        <div className="col-sm-5 col-md-3 col-lg-3 align-end">
                                            <div className="input-group">
                                                <input type="text" className="form-control form-control-sm" placeholder="Search...." onChange={handleSearchInput} />
                                                &nbsp;
                                                <button className="btn btn-sm btn-success" title="Reload" onClick={handleReloadClick}>
                                                    <i className="fa fa-refresh"></i>
                                                </button>
                                                &nbsp;
                                                <button className="btn btn-sm btn-success" title="Download" onClick={handleDownloadClick}>
                                                    <i className="fa fa-download"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <table className={`table table-striped table-hover table-sm visitList visitList3 mt-1`}>
                                        <thead>
                                            <tr>
                                                <th className="sort" onClick={() => handleSortClick("name", "string")}>
                                                    <span className="d-none d-lg-block">Route</span>
                                                    <span className="d-block d-lg-none">Route</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <FDARouteListTableBody currentPage={Number(pageNumber)} numberPerPage={Number(pageSize)} items={displayData} filter={filter} loading={loading} loadingError={loadingError} handleItemClick={handleRowClick} />
                                        </tbody>
                                        <tfoot>
                                            <FDARouteListTableFooter handlePageNumberChange={handlePageNumberChange} handlePageSizeChange={handlePageSizeChange} numPages={numPages} numRecords={displayData?.length ?? 0} pageSize={pageSize} />
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {showFDARouteModal && <FDARouteModal data={selectedFDARoute} closeDialog={() => setShowFDARouteModal(false)} />}
        </div>
    );
};
