export const getCard11Data = () => {
    return {
        title: "Admin Feature 11",
        description: "Just some amazing feature 11.",
        icon: "CardIcon_Card11.svg",
        iconHover: "CardIcon_Card11_blue.svg",
        cardLink: "/",
        section: "Jobs",
    };
};
