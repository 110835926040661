export const getAssessmentTypeManagementData = () => {
    return {
        title: "Assessment Cards",
        description: "Set assessment cards default Status with a click!",
        icon: "CardIcon_branchFeatureConfig.svg",
        iconHover: "CardIcon_branchFeatureConfig_blue.svg",
        cardLink: "/assessmentCards",
        section: "ATK",
    };
};
