import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";
import { acquireAccessToken } from "../lib/acquireAccessToken";

const acquireAccessTokenxxx = async (msalInstance) => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();
    if (!activeAccount && accounts.length === 0) {
        /*
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        return null;
    }
    const request = {
        scopes: ["User.Read"],
        account: activeAccount || accounts[0],
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken;
};

function handleLogin(instance) {
    instance
        //.loginPopup(loginRequest)
        .loginRedirect()
        .then(async (response) => {
            //alert(1);
            //console.log("response", JSON.stringify(response));
            const myAccounts: AccountInfo[] = instance.getAllAccounts();
            const token = await acquireAccessToken();
        })
        .catch((e) => {
            console.error(e);
        });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <Button variant="success" className="ml-auto h4" onClick={() => handleLogin(instance)}>
            Sign in
        </Button>
    );
};
