import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loginRequest, msalConfig } from "../authConfig";

export const acquireAccessToken = async () => {
    // This should be the same instance you pass to MsalProvider
    const msalInstance = new PublicClientApplication(msalConfig);

    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        return null;
    }
    const request = {
        scopes: ["User.Read"],
        account: activeAccount || accounts[0],
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken;
};

export const acquireMSALTokens = async () => {
    // This should be the same instance you pass to MsalProvider
    const msalInstance = new PublicClientApplication(msalConfig);

    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        return null;
    }
    const request = {
        scopes: ["User.Read"],
        account: activeAccount || accounts[0],
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return { idToken: authResult.idToken, accessToken: authResult.accessToken };
};

export const AcquireAccessToken = () => {
    const { instance, accounts } = useMsal();
    const [tkn, setTkn] = useState(null);

    useEffect(() => {
        function getToken() {
            const request = {
                ...loginRequest,
                account: accounts[0],
            };

            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance
                .acquireTokenSilent(request)
                .then((response) => {
                    setTkn(response.accessToken);
                })
                .catch((e) => {
                    console.error("error", e);
                    instance.acquireTokenPopup(request).then((response) => {
                        setTkn(response.accessToken);
                    });
                });
        }

        getToken();
    }, []);

    return <span>{tkn}</span>;
};
