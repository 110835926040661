//css
import "./fdaMedicationModal.css";

//Icons
//import icon from "../../../../assets/wheelchair-solid-green.svg";

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { getFDAMedicationsById, saveFDAMedication } from "../../../../api/fdaMedicationAPI";
import { getAllFDADosageTypes } from "../../../../api/fdaDosageTypeAPI";
import { getAllFDARoutes } from "../../../../api/fdaRouteAPI";

export const FDAMedicationModal = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Medication data
    const fdaMedicationDataEmpty = {
        id: null,
        name: null,
        fdaMedicationDosageTypes: [],
    };

    //dosage type data
    const dosageTypeDataEmpty = {
        fdaMedicationId: null,
        fdaDosageTypeId: null,
        fdaDosageType: null,
        fdaMedicationDosageTypeRoutes: [],
        id: null,
    };

    //FDAMedication data state
    const [fdaMedicationData, setFDAMedicationData] = useState(fdaMedicationDataEmpty);

    //saving state
    const [saving, setSaving] = useState(false);
    const [adding, setAdding] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [loading, setLoading] = useState(false);

    //Dosage Type data state
    const [dosageTypeData, setDosageTypeData] = useState(null);

    //Form mode state (new or edit)
    const [formMode, setFormMode] = useState(null);

    //show dosage type form state
    const [showDosageTypeForm, setShowDosageTypeForm] = useState(false);

    //Dose Type Options state
    const [doseTypeOptions, setDoseTypeOptions] = useState([]);
    const [routeOptions, setRouteOptions] = useState([]);

    //errors state
    const [loadingError, setLoadingError] = useState(null);
    const [operationError, setOperationError] = useState(null);

    //Selected Dosage Type index
    const [selectedDosageTypeIndex, setSelectedDosageTypeIndex] = useState(null);

    //=== Methods ============================================================

    const loadFDAMedication = (id) => {
        setLoadingError(null);
        setLoading(true);
        getFDAMedicationsById(id)
            .then((data) => {
                console.log("FDAMedication", data);
                setFDAMedicationData(data);
                setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
                setLoading(false);
            });
    };

    const loadAllFDADosageTypes = () => {
        setLoadingError(null);
        setLoading(true);
        getAllFDADosageTypes()
            .then((data) => {
                setDoseTypeOptions(data);
                setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
                setLoading(false);
            });
    };

    const loadAllFDARoutes = () => {
        setLoadingError(null);
        setLoading(true);
        getAllFDARoutes()
            .then((data) => {
                setRouteOptions(
                    data?.map((i) => {
                        return { label: i.name, value: i.id };
                    })
                );
                setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
                setLoading(false);
            });
    };

    //=== Use Effects ========================================================

    useEffect(() => {
        loadAllFDADosageTypes();
        loadAllFDARoutes();
    }, []);

    useEffect(() => {
        if (data?.id) loadFDAMedication(data?.id);
    }, [data]);

    //=== Handlers ===========================================================

    const editDosageType = (index) => {
        const newData = fdaMedicationData?.fdaMedicationDosageTypes[index];
        setSelectedDosageTypeIndex(index);
        setDosageTypeData(newData);
        setFormMode("Edit");
        setShowDosageTypeForm(true);
    };

    const handleAddNewDosageType = () => {
        const newData = dosageTypeDataEmpty;
        setDosageTypeData(newData);
        setFormMode("New");
        setShowDosageTypeForm(true);
    };

    const handleReset = () => {
        const newData = { id: fdaMedicationData?.id };
        setFDAMedicationData(newData);
    };

    const handleRoutesChange = (e) => {
        let routesNew = e?.map((i) => {
            const foundRoute = routeOptions.find((r) => r.value === i.value);

            return { fdaMedicationDosageTypeId: dosageTypeData?.id, fdaRouteId: i.value, fdaRoute: { id: foundRoute?.value, name: foundRoute?.label } };
        });
        const newData = { ...dosageTypeData, fdaMedicationDosageTypeRoutesOld: dosageTypeData?.fdaMedicationDosageTypeRoutes, fdaMedicationDosageTypeRoutes: routesNew };
        setDosageTypeData(newData);
    };

    const handleDoseTypeChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...dosageTypeData, [name]: value };
        setDosageTypeData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = {
            ...fdaMedicationData,
        };

        //===========================================================
        //Saving FDAMedication Data

        saveFDAMedication(saveObj)
            .then((ret) => {
                setFDAMedicationData(ret);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...fdaMedicationData, [name]: value };
        setFDAMedicationData(newData);
    };

    const handleAddDosageType = () => {
        const addObj = { ...dosageTypeData, fdaMedicationId: fdaMedicationData?.id, fdaDosageType: doseTypeOptions.find((d) => d.id === dosageTypeData.fdaDosageTypeId) };
        const newFdaMedicationData = { ...fdaMedicationData };

        //Adding
        //if (!newFdaMedicationData.fdaMedicationDosageTypes) newFdaMedicationData.fdaMedicationDosageTypes = [];
        newFdaMedicationData.fdaMedicationDosageTypes.push(addObj);

        console.log("newFdaMedicationData", newFdaMedicationData);

        //Setting FDAMedicationData
        setFDAMedicationData(newFdaMedicationData);
        setShowDosageTypeForm(false);
    };

    const handleUpdateDosageType = () => {
        const updateObj = { ...dosageTypeData, fdaMedicationId: fdaMedicationData?.id, fdaDosageType: doseTypeOptions.find((d) => d.id === dosageTypeData.fdaDosageTypeId) };
        const newFdaMedicationData = { ...fdaMedicationData };

        //Updating
        newFdaMedicationData.fdaMedicationDosageTypes[selectedDosageTypeIndex] = updateObj;

        //Setting FDAMedicationData
        setFDAMedicationData(newFdaMedicationData);
        setShowDosageTypeForm(false);
    };

    const handleRemoveDosageType = () => {
        const newFdaMedicationData = { ...fdaMedicationData };

        //Removing
        newFdaMedicationData.fdaMedicationDosageTypes.splice(selectedDosageTypeIndex, 1);

        //Setting FDAMedicationData
        setFDAMedicationData(newFdaMedicationData);
        setShowDosageTypeForm(false);
    };

    //=== Render ==============================================================

    return (
        <Modal dialogClassName="fdaMedicationModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            {/*
                            <img src={icon} width="24px" height="24px" alt="" className="icon mt-0 pt-0"></img>
                            */}
                        </span>
                        <span className="ps-2">Medication</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Name</span>
                            <input name="name" type="text" className="form-control" placeholder={`Medication Name`} value={fdaMedicationData?.name ?? ""} onChange={handleFieldChange} maxLength="30" readOnly={fdaMedicationData?.id} />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row m-0 p-0 mt-1 mb-2d">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                Dosage Types <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <table className="table table-sm mt-1">
                                <thead>
                                    <tr>
                                        <th>Dosage Type</th>
                                        <th>Routes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(fdaMedicationData?.fdaMedicationDosageTypes?.length ?? 0) === 0 && (
                                        <>
                                            <tr>
                                                <td colSpan="2" align="center">
                                                    No Dosage Types
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {fdaMedicationData?.fdaMedicationDosageTypes &&
                                        fdaMedicationData?.fdaMedicationDosageTypes.map((dosageType, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="small text-primary" onClick={() => editDosageType(index)}>
                                                        <a href="#dosageTypeForm">{dosageType?.fdaDosageType?.name}</a>
                                                    </td>
                                                    <td className="small">{dosageType.fdaMedicationDosageTypeRoutes.map((route) => route.fdaRoute.name).join(", ")}</td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                            <a href="#dosageTypeForm">
                                <button className="btn btn-sm btn-success" onClick={handleAddNewDosageType}>
                                    Add New Dosage Type
                                </button>
                            </a>
                        </div>
                    </div>

                    {showDosageTypeForm && (
                        <>
                            <div className="p-2 mt-3" style={{ backgroundColor: "rgba(88, 174, 137, 0.15)" }}>
                                <div className="row" id="dosageTypeForm">
                                    <div className="col-4">
                                        <span className="my-1 d-block">Dose Type</span>
                                        <select name="fdaDosageTypeId" className={`form-select`} onChange={handleDoseTypeChange} value={dosageTypeData?.fdaDosageTypeId ?? ""}>
                                            <option value="">Select</option>
                                            {doseTypeOptions &&
                                                doseTypeOptions.map((item, index) => (
                                                    <option key={index} value={item?.id}>
                                                        {item?.name}
                                                    </option>
                                                ))}
                                        </select>
                                        <div className="d-block text-danger small text-end"></div>
                                    </div>

                                    <div className="col-8">
                                        <span className="my-1 d-block">Routes</span>

                                        <Select
                                            isMulti
                                            name="routes"
                                            id="routes"
                                            menuPlacement="bottom"
                                            options={routeOptions}
                                            //value={listOfRequiredAssistance.value}
                                            //defaultValue={assistiveDevicesData?.requiresAssistanceFor?.map((dev) => listOfRequiredAssistance?.find((i) => i.value === dev))}
                                            value={(dosageTypeData?.fdaMedicationDosageTypeRoutes ?? []).map((r) => routeOptions?.find((i) => i.value === r?.fdaRouteId))}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                            onChange={handleRoutesChange}
                                        ></Select>

                                        <div className="d-block text-danger small text-end"></div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <DosageTypeButtons
                                            formMode={formMode}
                                            adding={adding}
                                            handleAdd={handleAddDosageType}
                                            updating={updating}
                                            handleUpdate={handleUpdateDosageType}
                                            removing={removing}
                                            handleRemove={handleRemoveDosageType}
                                            handleCancel={() => {
                                                setShowDosageTypeForm(false);
                                            }}
                                            readOnly={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={false} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};

export const DosageTypeButtons = ({ formMode, adding, handleAdd, updating, handleUpdate, removing, handleRemove, handleCancel, readOnly = false }) => {
    const [showRemoveConfirmationMessage, setShowRemoveConfirmationMessage] = useState(false);

    return (
        <>
            {formMode === "New" ? (
                <button type="button" className="btn btn-sm btn-outline-success" onClick={handleAdd}>
                    {!adding && "Add"}
                    {adding && (
                        <span className="ps-2">
                            <span className="pe-2">Adding...</span>
                            <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                        </span>
                    )}
                </button>
            ) : (
                <>
                    <button type="button" className="btn btn-sm btn-outline-success" onClick={handleUpdate}>
                        {!updating && "Update"}
                        {updating && (
                            <span className="ps-2">
                                <span className="pe-2">Updating...</span>
                                <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                            </span>
                        )}
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-danger ms-2"
                        onClick={() => {
                            setShowRemoveConfirmationMessage(true);
                        }}
                    >
                        {!removing && "Remove"}
                        {removing && (
                            <span className="ps-2">
                                <span className="pe-2">Removing...</span>
                                <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                            </span>
                        )}
                    </button>
                </>
            )}

            <button
                type="button"
                className="btn btn-sm btn-outline-secondary ms-2"
                onClick={() => {
                    handleCancel(false);
                }}
            >
                Cancel
            </button>

            {showRemoveConfirmationMessage && (
                <span className="alert alert-danger ms-4 py-2 my-1" role="alert">
                    <small>Are you sure you want to remove this dosage type?</small>
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary ms-2 my-0 py-0"
                        onClick={() => {
                            setShowRemoveConfirmationMessage(false);
                            handleRemove();
                        }}
                    >
                        <small>Yes</small>
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary ms-2 my-0 py-0"
                        onClick={() => {
                            setShowRemoveConfirmationMessage(false);
                        }}
                    >
                        <small>No</small>
                    </button>
                </span>
            )}
        </>
    );
};
