import { useNavigate } from "react-router-dom";
import "./adminCard.css";

export const AdminCard = ({ index, title, description, icon, iconHover, cardLink }) => {
    const navigate = useNavigate();

    const cardClickHandler = () => {
        navigate(cardLink); // Navega para a rota '/'
    };

    return (
        <div className="card adminCard" onClick={cardClickHandler}>
            <div className="card-body">
                <h5 className="card-title adminCardTitle">
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                        <span>
                            <img className="cardDefaultIcon" src={`${process.env.PUBLIC_URL}/assets/images/${icon}`} width="36px" height="36px" alt=""></img>
                            <img className="cardHoverIcon" src={`${process.env.PUBLIC_URL}/assets/images/${iconHover}`} width="40px" height="40px" alt=""></img>
                            {/*!icon && <i className={`fa ${iconClass} h1`} aria-hidden="true"></i>*/}
                        </span>
                        <span className="ps-2 h5">{title}</span>
                    </div>
                </h5>
                <p className="card-text small">{description}</p>
            </div>
        </div>
    );
};
